import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import Home from './Home/Home';
import Editor from "./App/Editor";
import Explorer from "./App/Explorer"

export default function App() {
  return (
    <Router>
        <Routes>
          <Route path="/editor" element={<Editor />} />
          <Route path="/" element={<Explorer />} />
          <Route path="/home" element={<Home />} />
        </Routes>
    </Router>
  )
}