
import './Home.css'
import Navigation from "../Navigation"
import Logo from "../Logo/Logo"

function Heading() {
  return  <div className='heading'>Type Fast Simply with Typefs</div>
}

function SubHeading() {
  return  <div className='sub-heading-container'><div className='sub-heading'>Typefs helps you save time on typing using predictive algorithms, shortcuts and highlighting important text</div></div>
}

function Access() {
    return <div className='access'>
       <a className='login' href='/login'>Login</a>
       <a className='signup' href='/signup'>Signup</a>
    </div>
}

function Try() {
  return <div className='try-container'>
    <a className='try' href='/try'>Signup now</a>
  </div>
}

function Demonstration() {
  return <div className='demonstration'>
    <iframe className='demonstration' src='/editor'></iframe>
    {/* <div className='demonstration-overlay'></div> */}
  </div>
}

function Home () {
    return <div>
    <div className='header'>
        <Logo />
        <Navigation />
        <Access />
    </div>
    <div className='container'>
        <Heading />
        <SubHeading />
        <Try />
        <Demonstration />
    </div>
  </div>
}

export default Home