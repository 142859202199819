const TokenType = {}
export default TokenType

let count = 0
TokenType.addTokenType = (type) => { TokenType[type] = count++ }
TokenType.addTokenType('COLON')
TokenType.addTokenType('HEADING')
TokenType.addTokenType('SENTENCE')
TokenType.addTokenType('UNCOMPLETED')
TokenType.addTokenType('UNCOMPLETED_BOL')
TokenType.addTokenType('INDEPENDENT_HEADING')
TokenType.addTokenType('EMPTY_SENTENCE')
TokenType.addTokenType('PARTIAL_SENTENCE')
TokenType.addTokenType('BREAK')
TokenType.addTokenType('LIST_ORDER')