import '../Header.css'
import './App.css'
import React, { useEffect, useState, useRef } from 'react'
import Select from './Select'
import { toJSON, toDOM } from 'dom-to-json'
import { DiffPatcher } from 'jsondiffpatch'
import Text from './Text'
import { MdTitle, MdTextSnippet, MdShortText, MdUndo, MdRedo, MdSave, MdCopyAll, MdContentCopy } from 'react-icons/md'
import fuzzysort from 'fuzzysort'
import Logo from '../Logo/Logo'
import TokenType from './TokenType'

const stopTabFunction = function (e) {
  if (e.code === 'Tab') e.preventDefault()
}
document.addEventListener('keydown', stopTabFunction)

const NGRAM_SIZE = 5

const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform)
const lastNonPunctuation = /[\s.,\/#!$%\^&\*;:{}=\-_`~()"](?!.*[\s.,\/#!$%\^&\*;:{}=\-_`~()"])/
const nonPunctuation = /^[\s.,\/#!$%\^&\*;:{}=\-_`~()"]/
const noneSearch = /\s$|[.,\/#!$%\^&\*;:{}=\-_`~()?"]$/
const learnMode = false
const nonBreakingSpace = '‍'
//const nonBreakingSpace = ' '


const jdp = new DiffPatcher()
// const complexData = `<span className='header'>Strong text 1<strong>Child of node 1</strong></span> <span className='sentence'>This is the first sentence</span>
//         <br></br><span className='header'>Strong text 2 <strong>Child of node 2</strong></span> <span className='sentence'>This is the second sentence</span>
//         <br></br><span className='header'>Strong text 3<strong>Child of node 3</strong></span> <span className='sentence'>This is the third sentence</span>`
// const realData = `<span class='heading'>Strong text:</span><span class="sentence">hello</span>`
const error = `<span class="heading">Appearance, attitude, activity:</span><span class="sentence">&nbsp;Arrogant.</span><span class="sentence">&nbsp;Akinetic.</span><br><span class="heading">Thought process:</span><span class="sentence">&nbsp;Normal thought process.</span><span class="sentence">&nbsp;Goal derealization.</span><span class="sentence">&nbsp;Goal circumstantial</span><br><span class="heading">Thought content:</span><span class="sentence">&nbsp;Linear.</span><span class="sentence">&nbsp;Abnormal thought content.</span><span class="sentence">&nbsp;Doubting.</span><span class="sentence">&nbsp;Acrophobia.</span><span class="sentence">&nbsp;Rumination</span><br><span class="uncompleted bol">Z</span><br><br><br>`
const guide = `<span class="sentence">How to use type FS?</span><br><br><span class="list order">1.</span><span class="sentence">Start typing and you will see suggestions</span><br><span class="list order">2.</span><span class="sentence">Selecting suggestions with this symbol will complete your word</span><br><span class="list order">3.</span><span class="sentence">Selecting suggestions with this symbol will insert a snippet</span><br><span class="list order">4.</span><span class="partial sentence">Selecting suggestions with this symbol will use data in a form to create </span><span class="uncompleted">texr</span><br>`
const pasteTest = `<span class="partial sentence">In vel iaculis felis n vel iaculis felis 1,n vel iaculis felis 1,n vel iaculis felis 1,n vel iaculis felis 1,n vel iaculis felis 1,n vel iaculis felis 1,n vel iaculis felis 1,</span><span class="sentence">1</span><span class="sentence">, </span><br><br><span class="sentence">In vel felis 2, </span><br><span class="empty sentence">&nbsp;&nbsp;&nbsp;&nbsp; </span><br><span class="sentence">In vel iaculis felis 3, </span><br><br><span class="partial sentence">In vel iaculis felis </span><span class="sentence">4</span><br><span class="sentence">to be input</span><br><br><span class="empty sentence">&nbsp;&nbsp; </span><span class="list order">1.</span><br><span class="empty sentence">&nbsp;&nbsp; </span><span class="list order">2.</span><br><span class="empty sentence">&nbsp;&nbsp;&nbsp; </span><span class="list order">3.</span><br><span class="empty sentence">&nbsp;&nbsp;&nbsp; </span><span class="list order">4.</span>`
const listOrdersTest = `<span class="list order">1.</span><span class="sentence">hello</span><br><span class="list order">2.</span><span class="sentence">hello</span><br><span class="list order">3.</span><span class="sentence">helllo</span><br><span class="list order">4.</span><span class="sentence"></span><br>`
const cyrb53 = function (str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

if (!Element.prototype.scrollIntoViewIfNeeded) {
  Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
    centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded;

    var parent = this.parentNode,
      parentComputedStyle = window.getComputedStyle(parent, null),
      parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width')),
      parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width')),
      overTop = this.offsetTop - parent.offsetTop < parent.scrollTop,
      overBottom = (this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight),
      overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft,
      overRight = (this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth),
      alignWithTop = overTop && !overBottom;

    if ((overTop || overBottom) && centerIfNeeded) {
      parent.scrollTop = this.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + this.clientHeight / 2;
    }

    if ((overLeft || overRight) && centerIfNeeded) {
      parent.scrollLeft = this.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + this.clientWidth / 2;
    }

    if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
      this.scrollIntoView(alignWithTop);
    }
  };
}

const scrollSelectionIntoView = () => {
  // Get current selection
  const selection = window.getSelection();

  // Check if there are selection ranges
  if (!selection.rangeCount) {
    return;
  }

  // Get the first selection range. There's almost never can be more (instead of firefox)
  const firstRange = selection.getRangeAt(0);

  // Sometimes if the editable element is getting removed from the dom you may get a HierarchyRequest error in safari
  if (firstRange.commonAncestorContainer === document) {
    return;
  }

  // Create an empty br that will be used as an anchor for scroll, because it's imposible to do it with just text nodes
  const tempAnchorEl = document.createElement('br');

  // Put the br right after the caret position
  firstRange.insertNode(tempAnchorEl);

  // Scroll to the br. I personally prefer to add the block end option, but if you want to use 'start' instead just replace br to span
  tempAnchorEl.scrollIntoView({
    block: 'end',
  });

  // Remove the anchor because it's not needed anymore
  tempAnchorEl.remove();

};

function getEmSize(el) {
  return Number(getComputedStyle(el, "").fontSize.match(/(\d+)px/)[1]);
}

function mod(n, m) {
  return ((n % m) + m) % m;
}

function processDiagnosis(text) {
  let values = text.split('\n')
  let normalizedValues = []
  values.forEach(v => {
    normalizedValues.push({
      type: 'diagnosis',
      display: v,
      value: v,
      priors: []
    })
  })
  return normalizedValues
}

function processSuggestions(text) {
  let keys = []
  let values = {}
  let lastHeader = null
  text.split('\n').forEach(v => {
    if (v.trim() === '') lastHeader = null
    if (!v.trim().endsWith(':') && lastHeader !== null) values[lastHeader].push(v)
    if (!v.trim().endsWith(':') && lastHeader === null) return
    if (!v.trim().endsWith(':') && lastHeader === !null) values[lastHeader].push(v)
    if (v.trim().endsWith(':')) lastHeader = v
    if (v.trim().endsWith(':') && !keys.includes(v)) keys.push(v)
    if (!values.hasOwnProperty(lastHeader)) values[lastHeader] = []
  })

  let normalizedValues = []
  for (const [key, value] of Object.entries(values)) {
    normalizedValues.push({
      type: 'heading',
      heading: '',
      display: key,
      value: key,
      priors: [],
    })

    // Full phrases
    value.forEach(v => {
      normalizedValues.push({
        type: 'text',
        heading: key,
        display: v,
        value: v,
        priors: [],
      })
    })

    // Part phrases
    value.forEach(v => {
      let words = v.split(' ')
      let lastWord = words.pop()
      while (typeof lastWord != 'undefined') {
        normalizedValues.push({
          type: 'text',
          heading: key,
          display: lastWord,
          value: lastWord,
          priors: [...words],
        })
        lastWord = words.pop()
      }
    })
  }
  return normalizedValues
}

function processSnippets(text) {
  let headings = []
  let values = {}
  let lastTitle = null
  let lastHeading = null
  let isLongForm = false
  text.split('\n').forEach(v => {
    if (isLongForm === false && v.trim() === '') lastTitle = null
    if (isLongForm === true && v.trim() === '###') lastTitle = null
    if (!v.trim().endsWith('#') && lastTitle !== null) values[lastTitle].push(v)
    if (!v.trim().endsWith('#') && lastTitle === null) return
    //if (!v.trim().endsWith('#') && lastTitle === !null) values[lastTitle].push(v)
    if (v.trim().endsWith('#')) {
      let components = v.trim().split(':')
      isLongForm = false
      if (v.trim().slice(-3) === '###') isLongForm = true
      lastTitle = components[components.length - 1]
      lastHeading = ''
      if (components.length === 2) lastHeading = components[0]
    }
    if (!values.hasOwnProperty(lastTitle)) {
      values[lastTitle] = []
      headings[lastTitle] = lastHeading.toLowerCase()
    }
  })

  let normalizedValues = []
  for (const [key, value] of Object.entries(values)) {
    let display = key.replaceAll('#', ' ').trim()
    normalizedValues.push({
      type: 'snippet',
      heading: headings[key],
      display: display,
      value: values[key].join('\n'),
      priors: [],
    })
  }
  return normalizedValues
}

function SuggestionType(props) {
  const type = props.type
  if (type === 'snippet') return <MdTextSnippet />
  if (type === 'text') return <MdShortText />
  if (type === 'heading') return <MdTitle />
  return <MdShortText />
}

function Button(props) {
  const onClick = props.onClick
  const type = props.type
  if (type === 'undo') return <button onClick={onClick}><MdUndo /><span className='tooltip'>Undo</span></button>
  if (type === 'redo') return <button onClick={onClick}><MdRedo /><span className='tooltip'>Redo</span></button>
  if (type === 'copy-to-clipboard') return <button onClick={onClick}><MdContentCopy /><span className='tooltip'>Copy to clipboard</span></button>
  if (type === 'save') return <button onClick={onClick}><MdSave /><span className='tooltip'>Save</span></button>
}

function Editor() {

  const [wordsToSearch, setWordsToSearch] = useState(1)
  const [suggestionBoxTop, setSuggestionBoxTop] = useState(null)
  const [suggestionBoxLeft, setSuggestionBoxLeft] = useState(null)
  const [suggestionBoxDisplay, setSuggestionBoxDisplay] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null)
  const [undoPatches, setUndoPatches] = useState([])
  const [redoPatches, setRedoPatches] = useState([])
  const [caret, setCaret] = useState([0, 0])
  const [lastValue, setLastValue] = useState([])
  const [lastSelection, setLastSelection] = useState([0, 0])
  const [targets, setTargets] = useState([])
  const [autocompleteLoaded, setAutocompleteLoaded] = useState(false)

  const editorRef = useRef();

  useEffect(() => {
    load()
    preSavePatch()
    //editorRef.current.innerHTML = pasteTest
    //editorRef.current.innerHTML = ''
    editorRef.current.innerHTML = listOrdersTest
    savePatch()
    editorRef.current.focus()
  }, [])

  useEffect(() => {
    makeSelectedSuggestionVisible()
  }, [selectedSuggestion])

  function load() {
    if (autocompleteLoaded === true) return
    fetch('./Snippets.txt')
      .then((r) => {
        return r.text()
      })
      .then(snippetsRaw => {
        fetch('./Suggestions.txt')
          .then((r) => {
            return r.text()
          })
          .then(suggestionsRaw => {
            fetch('./DSMDiagnosis.txt')
              .then((r) => {
                return r.text()
              })
              .then(diagnosisRaw => {
                setTargets([...processSuggestions(suggestionsRaw),
                ...processSnippets(snippetsRaw),
                ...processDiagnosis(diagnosisRaw)])
              })
          })
      })
    setAutocompleteLoaded(true)
  }

  function preSavePatch() {
    const selection = window.getSelection()
    setLastValue(Array.from(editorRef.current.childNodes, v => toJSON(v)))
    setLastSelection(Select.getSelection(editorRef.current, selection))
  }

  function savePatch() {
    const currentValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    setUndoPatches([...undoPatches, {
      patch: jdp.diff(lastValue, currentValue),
      select: lastSelection
    }])
    setRedoPatches([])
  }

  function updateCurrentLine(fullLine = false) {
    const selection = window.getSelection()
    let [preText, preNodes, preBreak] = Select.lineTextBeforeSelection(editorRef.current, selection)
    let [postText, postNodes, _] = Select.lineTextAfterSelection(editorRef.current, selection)
    if (preText === '' && postText === '') return false
    const mySelection = Select.getSelection(editorRef.current, selection)
    preNodes.forEach(v => v.remove())
    postNodes.forEach(v => v.remove())
    let tokens = null
    if (fullLine === true) tokens = Text.tokenizeLine(preText.replaceAll(nonBreakingSpace, '') + postText.replaceAll(nonBreakingSpace, ''), null, true)
    if (fullLine === false) tokens = Text.tokenizeLine(preText.replaceAll(nonBreakingSpace, ''), postText.replaceAll(nonBreakingSpace, ''))

    if (preBreak.tagName === 'DIV') {
      tokens.reverse().forEach(v => preBreak.prepend(v))
      Select.setSelection(editorRef.current, mySelection)
    }

    if (preBreak.tagName === 'BR') {
      tokens.reverse().forEach(v => preBreak.after(v))
      Select.setSelection(editorRef.current, mySelection)
    }

    console.log('pre', preBreak)

    return [preText, postText]
  }

  function updateCurrentLinePostInsertText() {
    return updateCurrentLine(false)
  }

  function updateCurrentLinePostDeleteContentBackward() {
    const selection = window.getSelection()
    const range = selection.getRangeAt(0)

    const myValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    let mySelection = Select.getSelection(editorRef.current, selection)
    
    let beginOrEnd = null
    let end = null
    let begin = null
    if ( range.startContainer.tagName === 'DIV') beginOrEnd = range.startContainer.childNodes[range.startOffset]
    if ( beginOrEnd !== null &&  beginOrEnd.nextSibling !== null && beginOrEnd.nextSibling.tagName !== 'BR' ) begin = beginOrEnd.nextSibling
    if ( beginOrEnd !== null &&  beginOrEnd.previousSibling !== null &&  beginOrEnd.previousSibling.tagName !== 'BR' ) end = beginOrEnd.previousSibling
    if ( end !== null && end.childNodes.length !== 0) {
      selection.removeAllRanges()
      let newRange = document.createRange()
      console.log(end, end.textContent.length)
      newRange.setStart(end.childNodes[0], end.childNodes[0].length)
      selection.addRange(newRange)
    }
    updateCurrentLine(true)

    // if ( range.startContainer.nodeType === Node.TEXT_NODE) {
    //   console.log('here', range.startContainer.length )
    //   console.log(range)
    //   //range.startContainer.parentNode.remove()
    // }


    const currentValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    setUndoPatches([...undoPatches, {
      patch: jdp.diff(myValue, currentValue),
      select: mySelection,
    }])

    // //let beforeListOrders = findBeforeListOrders()
    // return updateCurrentLine(false)
  }

  function updateCurrentLinePostSuggestion() {
    return updateCurrentLine(true)
  }

  function insertText(text) {
    let selection = window.getSelection()
    const myValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    let [preText, preNodes, preBreak] = Select.lineTextBeforeSelection(editorRef.current, selection)
    let [postText, postNodes, postBreak] = Select.lineTextAfterSelection(editorRef.current, selection)
    let mySelection = Select.getSelection(editorRef.current, selection)
    selection.deleteFromDocument()
    preNodes.forEach(v => v.remove())
    postNodes.forEach(v => v.remove())
    let newText = preText + text + postText
    let tokens = Text.tokenizeMultipleLines(newText)
    let newLines = 0
    tokens.forEach( v => { if (v.tagName === 'BR') newLines++ } )
    let earliestSelectionPoint = mySelection[0] > mySelection[1] ? mySelection[1] : mySelection[0]
    let earliestSelectionLine = mySelection[2] > mySelection[3] ? mySelection[3] : mySelection[2]
    let line = Select.getLineBreak(editorRef.current, earliestSelectionLine)

    // console.log('pre:', preText)
    // console.log('post:', postText)
    // console.log('text:', text)
    // console.log('text length', text.length)
    // console.log('tokens:', tokens)
    // console.log('preBreak:', preBreak)
    // console.log('mySelection:', mySelection)
    // console.log('newLines:', newLines)
    // console.log('early point:', earliestSelectionPoint)
    // console.log('early line:', earliestSelectionLine)
    // console.log('selection:', selection)

    if (preBreak != null && preBreak.tagName === 'DIV') {
      tokens.reverse().forEach(v => preBreak.prepend(v))

    }

    if (preBreak !== null && preBreak.tagName === 'BR') {
      tokens.reverse().forEach(v => preBreak.after(v))

    }

    if (preBreak === null) {
      if ( line === null) {
        tokens.reverse().forEach(v => editorRef.current.prepend(v) )
       
      }
      if ( line !== null) { 
        tokens.reverse().forEach(v => line.after(v) )
      
      }
    }

    Select.setSelection(editorRef.current, [
      earliestSelectionPoint + text.length - newLines,
      earliestSelectionPoint + text.length - newLines,
      earliestSelectionLine + newLines,
      earliestSelectionLine + newLines,
    ])

    const currentValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    setUndoPatches([...undoPatches, {
      patch: jdp.diff(myValue, currentValue),
      select: mySelection,
    }])

    return [true, null]
  }

  function insertSuggestion(index) {
    let autoCaps = true
    const selection = window.getSelection()
    const myValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    const mySelection = Select.getSelection(editorRef.current, selection)
    let uncompleted = document.querySelector('.uncompleted')
    let positionToReplace = 0
    let textRemainder = ''

    if (uncompleted != null) positionToReplace = uncompleted.textContent.search(nonPunctuation)
    if (uncompleted != null) textRemainder = uncompleted.textContent.substring(0, positionToReplace + 1)
    let textToReplaceWith = suggestions[index].obj.value
    let newText = textRemainder + textToReplaceWith
    if (uncompleted != null) uncompleted.textContent = newText

    let uncompletedPrevious = null
    if (uncompleted != null) uncompletedPrevious = uncompleted.previousSibling
    let isPartial = false
    if (uncompletedPrevious !== null && uncompletedPrevious.className === 'partial sentence' && uncompletedPrevious.textContent.trim() !== ''
    ) isPartial = true
    if (autoCaps && !isPartial) {
      let firstChar = uncompleted.textContent.search(/\S/)
      uncompleted.textContent = uncompleted.textContent.substring(0, firstChar) + uncompleted.textContent.charAt(firstChar).toUpperCase() + uncompleted.textContent.slice(firstChar + 1)
    }

    if (autoCaps && isPartial) {
      let firstChar = uncompleted.textContent.search(/\S/)
      uncompletedPrevious.textContent = uncompletedPrevious.textContent.substring(0, firstChar) + uncompletedPrevious.textContent.charAt(firstChar).toUpperCase() + uncompletedPrevious.textContent.slice(firstChar + 1)
    }

    selection.collapseToEnd()
    setSuggestionBoxDisplay(false)

    selection.removeAllRanges()
    let range = document.createRange()
    range.setStart(uncompleted.childNodes[0], uncompleted.textContent.length)
    selection.addRange(range)

    scrollSelectionIntoView()

    selection.removeAllRanges()
    range = document.createRange()
    range.setStart(uncompleted.childNodes[0], uncompleted.textContent.length)
    selection.addRange(range)
    updateCurrentLinePostSuggestion()

    const currentValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    setUndoPatches([...undoPatches, {
      patch: jdp.diff(myValue, currentValue),
      select: mySelection,
    }])
  }

  function insertSnippet(selectedSuggestion) {
    let selection = window.getSelection()

    const myValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    let mySelection = Select.getSelection(editorRef.current, selection)
    if (mySelection[2] === -1 || mySelection[3] === -1) mySelection = lastSelection
    if (mySelection[2] === -1 || mySelection[3] === -1) return [false, 'Invalid selection']

    Select.setSelection(editorRef.current, mySelection)
    selection = window.getSelection()

    let uncompleted = document.querySelector('.uncompleted')
    let positionToReplace = uncompleted.textContent.search(/\S/)
    let textRemainder = uncompleted.textContent.substring(0, positionToReplace)
    let textToReplaceWith = suggestions[selectedSuggestion].obj.value
    uncompleted.textContent = ''

    let [preText, preNodes, preBreak] = Select.lineTextBeforeSelection(editorRef.current, selection)
    let [postText, postNodes, _] = Select.lineTextAfterSelection(editorRef.current, selection)
    selection.deleteFromDocument()
    preNodes.forEach(v => v.remove())
    postNodes.forEach(v => v.remove())
    let newText = preText + textRemainder + textToReplaceWith + postText
    let tokens = Text.tokenizeMultipleLines(newText)

    if (preBreak.tagName === 'DIV') {
      tokens.reverse().forEach(v => preBreak.prepend(v))
    }

    if (preBreak.tagName === 'BR') {
      tokens.reverse().forEach(v => preBreak.after(v))
    }

    selection.removeAllRanges()
    let range = document.createRange()
    range.setStart(tokens[0].childNodes[0], tokens[0].textContent.length - postText.length)
    selection.addRange(range)
    selection.collapseToEnd()

    scrollSelectionIntoView()

    range = document.createRange()
    range.setStart(tokens[0].childNodes[0], tokens[0].textContent.length - postText.length)
    selection.addRange(range)
    selection.collapseToEnd()

    const currentValue = Array.from(editorRef.current.childNodes, v => toJSON(v))

    setUndoPatches([...undoPatches, {
      patch: jdp.diff(myValue, currentValue),
      select: mySelection,
    }])

    setSuggestionBoxDisplay(false)
  }

  function breakLine() {
    let selection = window.getSelection()


    if (selection.focusNode.tagName === 'DIV') {
      console.log('init')
      const br = document.createElement('BR')
      const selection = window.getSelection()
      selection.getRangeAt(0).insertNode(br)
      if (br.nextSibling === null && br.previousSibling === null) selection.getRangeAt(0).insertNode(document.createElement('BR'))
      selection.removeAllRanges()
      let range = document.createRange()
      range.setEndAfter(br)
      range.collapse(false)
      selection.addRange(range)

      scrollSelectionIntoView()

      range = document.createRange()
      range.setEndAfter(br)
      range.collapse(false)
      selection.addRange(range)

      refocusAfterListOrder()

      return false
    }

    let [preText, preTraversedNodes, preBreak] = Select.lineTextBeforeSelection(editorRef.current, selection)
    let [postText, postTraversedNodes, _] = Select.lineTextAfterSelection(editorRef.current, selection)
    preTraversedNodes.forEach(v => v.remove())
    postTraversedNodes.forEach(v => v.remove())
    selection.deleteFromDocument()
    const br = document.createElement('br')
    const secondBr = document.createElement('br')

    const preNodes = Text.tokenizeLine(preText, null, true)
    const postNodes = Text.tokenizeLine(postText, null, true)

    // List
    let listOrder = null
    let nextListOrder = null
    if (preNodes.length > 1 && preNodes[0].className === 'list order') listOrder = parseInt(preNodes[0].textContent.replace('.', '').trim())
    if (listOrder !== null) nextListOrder = Text.createNode(TokenType.LIST_ORDER, listOrder + 1 + '.' )

    if (preBreak.tagName === 'DIV') {
      console.log('div')
      postNodes.reverse().forEach(v => preBreak.prepend(v))
      if (nextListOrder != null) preBreak.prepend(nextListOrder)
      preBreak.prepend(br)
      if (postText.length === 0 && br.nextSibling === null) preBreak.prepend(secondBr)
      preNodes.reverse().forEach(v => preBreak.prepend(v))
      selection.removeAllRanges()
      let range = document.createRange()
      range.setEndAfter(br)
      if (nextListOrder != null) range.setStart(nextListOrder, nextListOrder.textContent.length - 1)
      range.collapse(false)
      selection.addRange(range)

      scrollSelectionIntoView()
    }


    if (preBreak.tagName === 'BR') {
      console.log('br')
      console.log(postNodes, preNodes, preText, postText)
      postNodes.reverse().forEach(v => preBreak.after(v))
      preBreak.after(br)
      preNodes.reverse().forEach(v => preBreak.after(v))
      selection.removeAllRanges()
      let aSpace = null
      if (nextListOrder != null) br.after(nextListOrder)
      if (br.nextSibling === null ||
        (br.nextSibling !== null && br.nextSibling.textContent.trim() === '')) {
        aSpace = document.createTextNode(nonBreakingSpace)
        br.after(aSpace)
      }
      let range = document.createRange()
      range.setEndAfter(br)
      if (nextListOrder != null) range.setStart(nextListOrder, nextListOrder.textContent.length - 1)
      range.collapse(false)
      selection.addRange(range)

      scrollSelectionIntoView()
    }

    refocusAfterListOrder()
  }

  function makeSelectedSuggestionVisible() {
    let s = document.querySelector('.selected-suggestion')
    if (s != null) s.scrollIntoViewIfNeeded(false)
  }

  function findClosestPriorHeading(beginNode) {
    let currentElement = beginNode.previousSibling
    let lastNodeIsBr = false
    while (currentElement) {
      if (currentElement.nodeName === 'SPAN' && (currentElement.className === 'heading' || currentElement.className === 'independent heading')) {
        return currentElement.textContent.trim().replace(':', '').toLowerCase()
      }
      if (currentElement.nodeName !== 'BR') lastNodeIsBr = false
      if (lastNodeIsBr && currentElement.nodeName === 'BR') return null
      if (currentElement.nodeName === 'BR') lastNodeIsBr = true

      currentElement = currentElement.previousSibling
    }
    return null
  }

  function isPreviousLineEmpty(beginNode) {
    let currentElement = beginNode.previousSibling
    let lastBreak = null
    while (currentElement) {
      if (currentElement.nodeName === 'BR') lastBreak = currentElement
      if (currentElement.nodeName === 'BR') break
      currentElement = currentElement.previousSibling
    }
    if (lastBreak != null && lastBreak.previousSibling != null && lastBreak.previousSibling.nodeName === 'BR') return true
    return false
  }

  function isFirstLine(beginNode) {
    return beginNode.previousSibling === null
  }

  function findBeforeListOrders(beginNode) {
    let listOrders = []
    let currentElement = beginNode.previousSibling
    while (currentElement) {
      currentElement = currentElement.previousSibling
    }
    return listOrders
  }

  function findAfterListOrders(beginNode) {

  }

  function getMaximumSearchWordCount() {
    let uncompleted = document.querySelector('.uncompleted')
    if (uncompleted === null) return 0
    return uncompleted.textContent.split(' ').filter(v => v.trim() !== '').length
  }

  function truncateSearch(words) {
    const selection = window.getSelection()
    let uncompleted = document.querySelector('.uncompleted')
    if (uncompleted === null) return false
    let newDOM = []

    newDOM = Text.tokenizeUncompletedByWordLimit(uncompleted.textContent, words)

    if (newDOM.length > 1) {
      uncompleted.before(newDOM[0])
      uncompleted.textContent = newDOM[1].textContent
      selection.removeAllRanges()
      const range = document.createRange()
      range.setStart(uncompleted.childNodes[0], uncompleted.textContent.length)
      range.setEnd(uncompleted.childNodes[0], uncompleted.textContent.length)
      selection.addRange(range)
    }
  }

  function placeSuggestion(results) {
    const selection = window.getSelection()
    setLastSelection(Select.getSelection(editorRef.current, selection))
    selection.collapseToEnd()

    let coordinates = getCoords(selection.getRangeAt(0))
    let editorCoordinates = editorRef.current.parentElement.getBoundingClientRect()
    let displayCoordinates = selection.getRangeAt(0).getBoundingClientRect()
    let availableSpaceOnBottom = (editorCoordinates.bottom - displayCoordinates.bottom) / getEmSize(editorRef.current)
    setSuggestionBoxLeft(coordinates.left)
    setSuggestionBoxTop(coordinates.bottom)

    let maxSuggestions = (results > 10) ? 10 : results
    if (availableSpaceOnBottom < 10) setSuggestionBoxTop(coordinates.bottom - (getEmSize(editorRef.current) + 1) * (maxSuggestions + 1))
    setSuggestionBoxDisplay(true)
    setSelectedSuggestion(0)
  }

  function searchSuggestion() {
    let uncompleted = document.querySelector('.uncompleted')
    if (uncompleted === null) return false
    let priors = []
    let bol = false
    let previousSibling = uncompleted.previousSibling
    if (previousSibling !== null && previousSibling.tagName === 'BR') bol = true
    if (previousSibling === null) bol = true
    if (previousSibling !== null && previousSibling.className === 'partial sentence') priors = previousSibling.textContent.split(/\s+/).filter(v => v !== '')
    let closestPriorHeading = findClosestPriorHeading(uncompleted)
    let previousLineEmpty = isPreviousLineEmpty(uncompleted)
    let firstLine = isFirstLine(uncompleted)

    let text = uncompleted.textContent.trim().replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()"]/g, '').replaceAll(nonBreakingSpace, '')

    priors = priors.slice(-1 * (NGRAM_SIZE - 1)).map(v => v.trim())
    //console.log(`Begin: ${bol} | Priors count: ${priors.length} | Priors: ${priors.join(',')} | Uncompleted: ${text} `)

    const options = {
      limit: 50, // don't return more results than you need!
      threshold: -10000, // don't return bad results
      scoreFn: a => Math.max(a[0] ? a[0].score : -1000, a[1] ? a[1].score - 100 : -1000),
      keys: ['display', 'value']
    }
    let newTargets = []
    let results = []

    if (previousLineEmpty || firstLine) {
      newTargets = targets.filter(t => t.type === 'heading')
      results = [...results, ...fuzzysort.go(text, newTargets, options).filter(t => t.obj !== 'undefined').filter(t => t[0] !== null)]
    }

    // Closest prior heading
    while (results.length < 50) {
      newTargets = targets.filter(t => t.priors.length === priors.length && t.heading === closestPriorHeading)
      results = [...results, ...fuzzysort.go(text, newTargets, options).filter(t => t.obj !== 'undefined').filter(t => t[0] !== null)]
      let returned = priors.shift()
      if (typeof returned === 'undefined') break
    }

    // Without closest prior heading
    while (results.length < 50) {
      newTargets = targets.filter(t => t.priors.length === priors.length && t.heading !== closestPriorHeading && t.type !== 'heading')
      results = [...results, ...fuzzysort.go(text, newTargets, options).filter(t => t.obj !== 'undefined').filter(t => t[0] !== null)]
      let returned = priors.shift()
      if (typeof returned === 'undefined') break
    }

    // Give the headings a chance, always
    if (results.length < 50) {
      newTargets = targets.filter(t => t.type === 'heading')
      results = [...results, ...fuzzysort.go(text, newTargets, options).filter(t => t.obj !== 'undefined').filter(t => t[0] !== null)]
    }

    if (results.length === 0) setSuggestionBoxDisplay(false)
    let valueHash = []
    let uniqueResults = []
    results.forEach(v => {
      let hash = v.obj.display + v.obj.value
      if (valueHash.includes(hash)) return
      valueHash.push(hash)
      uniqueResults.push(v)
    })
    setSuggestions(uniqueResults)
    return uniqueResults.length
  }

  function onBeforeEnter(e) {
    const selection = window.getSelection()
    const myValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    const mySelection = Select.getSelection(editorRef.current, selection)
    breakLine()

    const currentValue = Array.from(editorRef.current.childNodes, v => toJSON(v))
    setUndoPatches([...undoPatches, {
      patch: jdp.diff(myValue, currentValue),
      select: mySelection,
    }])
    e.preventDefault()
    return false
  }

  function getCoords(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;
    var bottom = box.bottom + scrollTop - clientTop;
    var right = box.right + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left), bottom: Math.round(bottom), right: Math.round(right) };
  }

  function refocusAfterListOrder() {
    let selection = window.getSelection()
    let range = selection.getRangeAt(0)
    let emptySentence = null
    let listOrder = null
    if ( range.startContainer.parentNode.className === 'list order' ) listOrder = range.startContainer.parentNode
    if ( range.startContainer.className === 'list order' ) listOrder = range.startContainer
    if ( listOrder !== null ) emptySentence = Text.createNode(TokenType.SENTENCE, '')
    if (emptySentence === null) return false
    if (emptySentence !== null) listOrder.after(emptySentence)
    if (emptySentence !== null) {
      selection.removeAllRanges()
      let range = document.createRange()
      range.setStart(emptySentence, 0)
      selection.addRange(range)
    }
    return true
  }

  function onInsertText(e) {
    if (editorRef.current.childNodes.length === 1 &&
      editorRef.current.childNodes[0].nodeType === Node.TEXT_NODE) {
      editorRef.current.append(document.createElement('BR'))
    }
    const [preText, postText] = updateCurrentLinePostInsertText(false)

    /// TD: if sentence finishes with fullstop also learn
    if (noneSearch.test(preText)) {
      setSuggestionBoxDisplay(false)

      let typed = null
      let uncompleted = document.querySelector('.uncompleted')
      if (uncompleted !== null) typed = uncompleted
      if (uncompleted !== null) typed.className = 'sentence'
      if (preText.charAt(preText.length - 1) === '.') {
        let tokens = Text.tokenizeLine(preText, '', true)
        typed = tokens[tokens.length - 1]
      }
      if (typed === null) return

      if (learnMode === false) return
      let words = typed.textContent.replace(/\.+$/, '').split(' ').slice(-1 * NGRAM_SIZE * 2).map(v => v.trim()).filter(v => v !== '')
      let heading = findClosestPriorHeading(typed)
      let bow = []
      for (let i = 0; i < words.length; i++) {
        let left = words.slice(0, i)
        let right = words.slice(i)
        let rightFirstWord = right.shift()
        let rightPhrase = null

        while (typeof rightFirstWord != 'undefined') {
          if (rightPhrase !== null) rightPhrase = `${rightPhrase} ${rightFirstWord}`
          if (rightPhrase === null) rightPhrase = rightFirstWord
          let leftCopy = [...left]
          while (true) {
            bow.push({
              type: 'text',
              heading: heading,
              display: rightPhrase,
              value: rightPhrase,
              priors: [...leftCopy],
            })
            if (typeof (leftCopy.shift()) === 'undefined') break
          }
          rightFirstWord = right.shift()
        }
      }
      setTargets([...targets, ...bow])
      return false
    }

    setWordsToSearch(1)
    truncateSearch(1)
    let results = searchSuggestion()
    placeSuggestion(results)
  }

  function onDeleteContentBackward(e) {
    updateCurrentLinePostDeleteContentBackward()
    savePatch()
    preSavePatch()
  }

  function onDeleteContentForward(e) {
    updateCurrentLinePostDeleteContentBackward()
    savePatch()
    preSavePatch()
  }

  function syncUndo(container, delta) {
    const currentValue = Array.from(container.childNodes, v => toJSON(v))
    const newValue = jdp.unpatch(currentValue, delta)
    while (editorRef.current.firstChild) {
      editorRef.current.removeChild(editorRef.current.firstChild)
    }
    newValue.forEach(v => {
      let newChildNodes = []
      v.childNodes.forEach(c => {
        if (c.nodeType === Node.TEXT_NODE && !c.hasOwnProperty('nodeValue')) return
        newChildNodes.push(c)
      })
      v.childNodes = newChildNodes

      let newDom = toDOM(v)
      if (v.nodeType === Node.TEXT_NODE && !v.hasOwnProperty('nodeValue')) {
        newDom = document.createTextNode('')
      }
      editorRef.current.append(newDom)
    })
  }

  function syncRedo(container, delta) {
    const currentValue = Array.from(container.childNodes, v => toJSON(v))
    const newValue = jdp.patch(currentValue, delta)
    while (editorRef.current.firstChild) {
      editorRef.current.removeChild(editorRef.current.firstChild)
    }
    newValue.forEach(v => {
      let newChildNodes = []
      v.childNodes.forEach(c => {
        if (c.nodeType === Node.TEXT_NODE && !c.hasOwnProperty('nodeValue')) return
        newChildNodes.push(c)
      })
      v.childNodes = newChildNodes
      let newDom = toDOM(v)

      if (v.nodeType === Node.TEXT_NODE && !v.hasOwnProperty('nodeValue')) {
        newDom = document.createTextNode('')
      }
      editorRef.current.append(newDom)
    }
    )
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(Text.nodesToText(editorRef.current).replaceAll(nonBreakingSpace, ''))
  }

  function onHistoryUndo(e) {
    setSuggestionBoxDisplay(false)
    if (undoPatches.length === 0) return false
    let lastPatch = undoPatches.pop()
    const preUndoSelection = Select.getSelection(editorRef.current, window.getSelection())
    syncUndo(editorRef.current, lastPatch.patch)
    Select.setSelection(editorRef.current, lastPatch.select)
    setUndoPatches(undoPatches)
    setRedoPatches([...redoPatches, { patch: lastPatch.patch, select: preUndoSelection }])
    e.preventDefault()
    return false
  }

  function onHistoryRedo(e) {
    setSuggestionBoxDisplay(false)
    if (redoPatches.length === 0) return false
    let lastPatch = redoPatches.pop()
    const preRedoSelection = Select.getSelection(editorRef.current, window.getSelection())
    syncRedo(editorRef.current, lastPatch.patch)
    Select.setSelection(editorRef.current, lastPatch.select)
    setUndoPatches([...undoPatches, { patch: lastPatch.patch, select: preRedoSelection }])
    setRedoPatches(redoPatches)
    e.preventDefault()
    return false
  }

  function onSuggestionArrowUp(e) {
    setSelectedSuggestion(mod(selectedSuggestion - 1, suggestions.length))
    e.preventDefault()
    return false
  }

  function onSuggestionArrowDown(e) {
    setSelectedSuggestion(mod(selectedSuggestion + 1, suggestions.length))
    e.preventDefault()
    return false
  }


  function onSuggestionArrowLeft(e) {
    updateCurrentLine(true)
  }

  function onSuggestionArrowRight(e) {
    updateCurrentLine(true)
  }

  function onSuggestionEnter(e) {
    e.preventDefault()
    if (suggestions[selectedSuggestion].obj.type === 'heading') return insertSuggestion(selectedSuggestion)
    if (suggestions[selectedSuggestion].obj.type === 'snippet') return insertSnippet(selectedSuggestion)
    return insertSuggestion(selectedSuggestion)
  }
  function onSuggestionTab(e) { }
  function onSuggestionShift(e) {
    e.preventDefault()
    updateCurrentLinePostInsertText()

    let wordsAvailable = getMaximumSearchWordCount()
    let wordsToSearchNow = wordsToSearch
    if (wordsToSearchNow > wordsAvailable) wordsToSearchNow = 1
    if (wordsToSearchNow <= wordsAvailable) wordsToSearchNow += 1
    truncateSearch(wordsToSearchNow)
    setWordsToSearch(wordsToSearchNow)
    let results = searchSuggestion()
    if (results !== 0) return false
    placeSuggestion(results)

    // If no results
    updateCurrentLinePostInsertText()
    placeSuggestion(0)
    wordsToSearchNow = 1
    truncateSearch(wordsToSearchNow)
    setWordsToSearch(wordsToSearchNow)
    searchSuggestion()
    return false
  }
  function onTab(e) { }
  function onBackspace(e) { }
  function onSpace(e) { 
    if (refocusAfterListOrder() === true) e.preventDefault()
  }

  function onKeyDown(e) {
    if (e.code === 'ArrowUp' && suggestionBoxDisplay) return onSuggestionArrowUp(e)
    if (e.code === 'ArrowDown' && suggestionBoxDisplay) return onSuggestionArrowDown(e)
    if (e.code === 'ArrowLeft' && suggestionBoxDisplay) onSuggestionArrowLeft(e)
    if (e.code === 'ArrowRight' && suggestionBoxDisplay) onSuggestionArrowRight(e)
    if (e.code === 'Enter' && suggestionBoxDisplay && suggestions.length > 0) return onSuggestionEnter(e)
    if (e.code === 'Tab' && suggestionBoxDisplay) return onSuggestionTab(e)
    setSuggestionBoxDisplay(false)
  
    if ((e.code === 'ShiftLeft' || e.code === 'ShiftRight') && suggestionBoxDisplay) return onSuggestionShift(e)
    if (e.code === 'Enter') return onBeforeEnter(e)
    if (e.code === 'Tab') return onTab(e)
    if (e.code === 'Backspace') return onBackspace(e)
    if (e.code === 'Space') return onSpace(e)

    // Mac specific
    if (isMacLike === true && e.metaKey && e.shiftKey && e.code === 'KeyZ') return onHistoryRedo(e)
    if (isMacLike === true && e.metaKey && e.code === 'KeyZ') return onHistoryUndo(e)

    // PC specific
    if (isMacLike === false && e.ctrlKey && e.shiftKey && e.code === 'KeyZ') return onHistoryRedo(e)
    if (isMacLike === false && e.ctrlKey && e.code === 'KeyZ') return onHistoryUndo(e)
  }

  function onInput(e) {
    setSuggestionBoxDisplay(false)
    const inputType = e.nativeEvent.inputType
    if (inputType === 'historyUndo') return onHistoryUndo(e)
    if (inputType === 'insertFromPaste') return false
    if (inputType === 'insertParagraph') return false
    if (inputType === 'deleteContentBackward') onDeleteContentBackward(e)
    if (inputType === 'deleteContentForward') onDeleteContentForward(e)
    if (inputType === 'insertText') onInsertText(e)
    console.log(inputType)
    savePatch()
  }

  function onBeforeInput(e) {
    preSavePatch()
  }

  function onPaste(e) {
    let paste = (e.clipboardData || window.clipboardData).getData('text')
    insertText(paste)
    e.preventDefault();
  }

  function onCopy(e) {
    navigator.clipboard.writeText(window.getSelection().toString().replaceAll(nonBreakingSpace, ''))
    e.preventDefault()
  }

  function onFocus(e) {
    const selection = window.getSelection()
    setLastSelection(Select.getSelection(editorRef.current, selection))
  }

  function onClick(e) {
    const selection = window.getSelection()
    let currentCaretPosition = Select.getSelection(editorRef.current, selection)
    if (currentCaretPosition[0] === lastSelection[0] &&
      currentCaretPosition[1] === lastSelection[1] &&
      currentCaretPosition[2] === lastSelection[2] &&
      currentCaretPosition[3] === lastSelection[3]
    ) return false

    let lineNumber = lastSelection[3]
    let nodes = Select.getLineAt(editorRef.current, lineNumber)
    Text.completeNodes(nodes)
    setSuggestionBoxDisplay(false)
    // //setLastSelection(Select.getSelection(editorRef.current, selection))
    //Select.getSelection(currentCaretPosition)
    setLastSelection(currentCaretPosition)
  }

  function getSelectionData() {
    let selection = window.getSelection()
    let newCaret = Select.getSelection(editorRef.current, selection)
    setCaret(newCaret)
    console.log(newCaret)
  }

  function setSelection() {
    Select.setSelection(editorRef.current, caret)
  }

  function onSuggestionClick(e, k) {
    let selectedSuggestion = parseInt(e.target.getAttribute('data-index'))
    if (suggestions[selectedSuggestion].obj.type === 'heading') return insertSuggestion(selectedSuggestion)
    if (suggestions[selectedSuggestion].obj.type === 'snippet') {
      return insertSnippet(selectedSuggestion)
    }
    return insertSuggestion(selectedSuggestion)
  }

  function onSuggestionMouseOver(e) {
    let selectionIndex = e.target.getAttribute('data-index')
    setHoveredSuggestion(parseInt(selectionIndex))
  }

  function onScroll(e) {
    if (suggestionBoxDisplay) placeSuggestion(suggestions.length)
  }

  return (
    <div className='main-container'>
      <div className='header'>
        <Logo />
        <div className='paper-toolbar'>
          <div className='document-title-container'><input className="document-title" placeholder='Untitled document' /><span className='tooltip'>Document title</span>
          </div>
          <div className='buttons'>
            <Button type='undo' onClick={onHistoryUndo}></Button>
            <Button type='redo' onClick={onHistoryRedo}></Button>
            <Button type='copy-to-clipboard' onClick={copyToClipboard}></Button>
            {/* <Button type='save' onClick={onHistoryRedo}></Button> */}
          </div>
        </div>
      </div>
      <div className='editor-container' onScroll={onScroll}>
        {/* <button onClick={getSelectionData}>Save caret position</button>
        <button onClick={setSelection}>Select caret position</button> */}
        <div
          ref={editorRef}
          className='paper'
          contentEditable
          onKeyDown={onKeyDown}
          onInput={onInput}
          onBeforeInput={onBeforeInput}
          onPaste={onPaste}
          onFocus={onFocus}
          onClick={onClick}
          onCopy={onCopy}
          spellCheck="false"
          suppressContentEditableWarning="true"
        >
        </div>
        <ul className={`suggestion-box ${suggestionBoxDisplay !== true && 'hide'}`} style={{ left: suggestionBoxLeft, top: suggestionBoxTop }}>
          {suggestions.map((v, i) =>
            <li key={i} className={`${hoveredSuggestion === i && 'hovered-suggestion'} ${selectedSuggestion === i && 'selected-suggestion'} `} data-index={i} onClick={onSuggestionClick} onMouseOver={onSuggestionMouseOver}><SuggestionType type={v.obj.type} />{v[0] !== null ? fuzzysort.highlight(v[0], (m, i) => <mark key={i}>{m}</mark>) : v.obj.display}</li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Editor