import TokenType from "./TokenType"

const Text = {}
const Re = new RegExp(String.fromCharCode(160), "g")
export default Text
Text.tokenizeLine = (preCursor, postCursor = '', assumeCompleted = false) => {
    if (postCursor === null) {
        const sentences = splitStr(preCursor.replaceAll(Re, ' '))
        const tokens = tokenizeTextSnippets(sentences, true, assumeCompleted)
        const doms = tokens.map(v => { return toDOM(v) })
        return doms
    }

    const preCursorSentences = splitStr(preCursor.replaceAll(Re, ' '))
    const preCursorTokens = tokenizeTextSnippets(preCursorSentences, true, false)
    const preCursorDoms = preCursorTokens.map(v => { return toDOM(v) })

    const postCursorSentences = splitStr(postCursor.replaceAll(Re, ' '))
    const postCursorTokens = tokenizeTextSnippets(postCursorSentences, false, true)
    const postCursorDoms = postCursorTokens.map(v => { return toDOM(v) })

    return [...preCursorDoms, ...postCursorDoms]
}
Text.tokenizeMultipleLines = (text) => {
    const lines = text.split('\n')
    let doms = []
    let lineCount = 0
    let finalLine = lines.length - 1
    lines.forEach(line => {
        //let sentences = splitStr(line.replaceAll(/\s/g, '\u00A0'))
        let sentences = splitStr(line.replaceAll(Re, ' '))
        let sentenceTokens = tokenizeTextSnippets(sentences, true, true)
        let sentenceDoms = sentenceTokens.map(v => { return toDOM(v) })
        if (lineCount !== finalLine) doms = [...doms, ...sentenceDoms, document.createElement('BR')]
        if (lineCount === finalLine) doms = [...doms, ...sentenceDoms]
        lineCount++
    })
    return doms
}
Text.tokenizeUncompletedByWordLimit = (text, wordsToRemainInComplete = 2) => {
    const words = text.replaceAll(Re, ' ').split(' ')
    if (words.length <= wordsToRemainInComplete) {
        return [toDOM({ type: TokenType.UNCOMPLETED, value: text })]
    }
    const preWords = words.slice(0, words.length - wordsToRemainInComplete)
    const postWords = words.slice(words.length - wordsToRemainInComplete)
    return [toDOM({ type: TokenType.PARTIAL_SENTENCE, value: preWords.join(' ') + ' ' }), toDOM({ type: TokenType.UNCOMPLETED, value: postWords.join(' ') })]
}
Text.completeNodes = (nodes) => {
    nodes.forEach(v => {
        if (v.className === UncompletedClass) v.className = SentenceClass
        if (v.className === UncompletedBolClass) v.className = SentenceClass
    })
}

Text.nodesToText = (container) => {
    let text = ''
    let currentNode = container.firstChild
    if (currentNode === null) return text
    while (currentNode !== null) {
        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.tagName !== 'BR') text += currentNode.textContent
        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.tagName === 'BR') text += '\n'
        currentNode = currentNode.nextSibling
    }
    return text
}

Text.createNode = (type, value) => {
    return toDOM({type: type , value: value})
}

const HeadingClass = 'heading'
const SentenceClass = 'sentence'
const UncompletedClass = 'uncompleted'
const UncompletedBolClass = 'uncompleted bol'
const IndependentHeadingClass = 'independent heading'
const EmptySentenceClass = 'empty sentence'
const PartialSentenceClass = 'partial sentence'
const BreakClass = 'break'
const ListOrder = 'list order'
const ColonClass = 'colon'

function Token(type, value) {
    return { type: type, value: value }
}

function toDOM(token) {
    if (token.type === TokenType.HEADING) return toHeading(token)
    if (token.type === TokenType.SENTENCE) return toSentence(token)
    if (token.type === TokenType.UNCOMPLETED) return toUncompleted(token)
    if (token.type === TokenType.UNCOMPLETED_BOL) return toUncompletedBol(token)
    if (token.type === TokenType.INDEPENDENT_HEADING) return toIndependentHeading(token)
    if (token.type === TokenType.EMPTY_SENTENCE) return toEmptySentence(token)
    if (token.type === TokenType.PARTIAL_SENTENCE) return toPartialSentence(token)
    if (token.type === TokenType.BREAK) return toBreak(token)
    if (token.type === TokenType.LIST_ORDER) return toListOrder(token)
    if (token.type === TokenType.COLON) return toPartialSentence(token)
}

function toHeading(token) {
    let span = document.createElement('span')
    span.className = HeadingClass
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    //span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function toSentence(token) {
    let span = document.createElement('span')
    span.className = SentenceClass
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    //span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function toUncompleted(token) {
    let span = document.createElement('span')
    span.className = UncompletedClass
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    //span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function toUncompletedBol(token) {
    let span = document.createElement('span')
    span.className = UncompletedBolClass
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function toIndependentHeading(token) {
    let span = document.createElement('span')
    span.className = IndependentHeadingClass
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function toEmptySentence(token) {
    let span = document.createElement('span')
    span.className = EmptySentenceClass
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function toPartialSentence(token) {
    let span = document.createElement('span')
    span.className = PartialSentenceClass
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function toBreak(token) {
    return document.createElement('BR')
}

function toListOrder(token) {
    let span = document.createElement('span')
    span.className = ListOrder
    let text = document.createTextNode(token.value)
    span.appendChild(text)
    span.innerHTML = token.value.replaceAll(' ', '&nbsp;')
    span.innerHTML = token.value
    return span
}

function closureHandler(c, layer) {
    if (c === '"' && layer.length > 0 && layer[layer.length - 1] !== '"') { layer.push(c); return layer; }
    if (c === '\'' && layer.length > 0 && layer[layer.length - 1] !== '\'') { layer.push(c); return layer; }
    if (c === '"' && layer.length === 0) { layer.push(c); return layer; }

    if (c === '(') { layer.push(c); }
    if (c === '{') { layer.push(c); }
    if (c === '[') { layer.push(c); }
    if (c === '<') { layer.push(c); }

    if (layer.length === 0) return layer;
    if (c === ')' && layer[layer.length - 1] === '(') { layer.shift(); }
    if (c === '}' && layer[layer.length - 1] === '{') { layer.shift(); }
    if (c === ']' && layer[layer.length - 1] === '[') { layer.shift(); }
    if (c === '>' && layer[layer.length - 1] === '<') { layer.shift(); }
    if (c === '"' && layer[layer.length - 1] === '"') { layer.shift(); }
    return layer;
}

function splitStr(str) {
    let layer = [];
    let splitPoints = [0];
    let expectingClose = '';
    let expectingCloseHanging = false;
    let expectingEndHanging = false;
    for (let i = 0; i < str.length; i++) {
        let c = str.charAt(i);

        // Expecting end hanging
        if (expectingEndHanging && c !== ' ') {
            expectingEndHanging = false;
            splitPoints.push(i);
            continue;
        }

        if (expectingEndHanging && c === ' ') {
            continue;
        }

        // Expecting close hanging
        if (expectingCloseHanging && c !== ' ') {
            expectingCloseHanging = false;
            expectingClose = '';
            splitPoints.push(i);
            layer.shift();
            continue;
        }

        if (expectingCloseHanging && c === ' ') {
            continue;
        }

        // Close hanging dots
        if (expectingClose !== '' & c === ' ') { continue; }
        if (expectingClose === c && (i + 1) < str.length &&
            str.charAt(i + 1) === ' ') {
            expectingCloseHanging = true;
            i++;
            continue;
        }

        // Immediate closing "...?" or "....(full stop)"
        if (c === '.' &&
            (i + 1) < str.length &&
            str.charAt(i + 1) === '"' &&
            layer.length > 0 &&
            layer[layer.length - 1] === '"') {
            i++;
            layer.shift();
            splitPoints.push(i + 1);
            continue;
        }

        if (c === '?' &&
            (i + 1) < str.length &&
            str.charAt(i + 1) === '"' &&
            layer.length > 0 &&
            layer[layer.length - 1] === '"') {
            i++;
            layer.shift();
            splitPoints.push(i + 1);
            continue;
        }

        // Expecting close but not yet close
        if (c === '.' &&
            (i + 1) < str.length &&
            str.charAt(i + 1) !== '"' &&
            layer.length > 0 &&
            layer[layer.length - 1] === '"') {
            i++;
            expectingClose = '"';
            continue;
        }

        if (c === '?' &&
            (i + 1) < str.length &&
            str.charAt(i + 1) !== '"' &&
            layer.length > 0 &&
            layer[layer.length - 1] === '"') {
            i++;
            expectingClose = '"';
            continue;
        }


        layer = closureHandler(c, layer);
        if (layer.length !== 0) { continue; }

        // Finishing with ':'

        if (c === ':' && (i + 1) < str.length && str[i + 1] === ' ') {
            splitPoints.push(i + 1);
            console.log('here')
            continue;
        }

        if (c === ':' && i === str.length) {
            splitPoints.push(i + 1);
            continue;
        }

        // Finishing with dot but not dots
        if (c === '.' && (i + 1) < str.length && str[i + 1] === ' ') {
            splitPoints.push(i + 1);
            // expectingEndHanging = true;
            // i++;
            continue;
        }

        if (c === '.') {
            splitPoints.push(i + 1);
            continue;
        }

        if (c === '.' && (i + 1) < str.length && !isNaN(str[i + 1])) {
            continue;
        }

        if (c === '.' && (i + 1) < str.length && str[i + 1] !== '.') {
            splitPoints.push(i + 1);
            continue;
        }

        // End of string
        if (c === '.' && i === str.length) {
            splitPoints.push(i + 1);
            continue;
        }

        // Finishing with ? but not ???
        if (c === '?' && (i + 1) < str.length && str[i + 1] === ' ') {
            expectingEndHanging = true;
            i++;
            continue;
        }

        // End of string
        if (c === '?' && (i + 1) < str.length && str[i + 1] === ' ') {
            splitPoints.push(i + 1);
            continue;
        }

    }
    let sentences = []

    for (let i = 0; i < (splitPoints.length - 1); i++) {
        sentences.push(str.substring(splitPoints[i], splitPoints[i + 1]));
    }
    sentences.push(str.substring(splitPoints[splitPoints.length - 1], str.length));
    return sentences;
}

function isInDesiredForm(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

function tokenizeTextSnippets(snippets, findHeading = false, assumeCompleted = false) {
    let tokens = []
    let hasHeading = findHeading ? false : true

    snippets.forEach(v => {
        let trimmedValue = v.trim()
        if (hasHeading && trimmedValue.endsWith(':')) return tokens.push(Token(TokenType.COLON, v))
        if (!hasHeading && trimmedValue.endsWith(':')) {
            hasHeading = true;
            return tokens.push(Token(TokenType.COLON, v))
        }
        if (v === '') return
        if (trimmedValue.endsWith('.') && isInDesiredForm(trimmedValue.slice(0, -1))) return tokens.push(Token(TokenType.LIST_ORDER, v))
        if (trimmedValue.endsWith('.')) return tokens.push(Token(TokenType.SENTENCE, v))
        if (trimmedValue.endsWith('"')) return tokens.push(Token(TokenType.SENTENCE, v))
        if (trimmedValue.endsWith('?')) return tokens.push(Token(TokenType.SENTENCE, v))
        if (trimmedValue.length === 0) return tokens.push(Token(TokenType.EMPTY_SENTENCE, v))
        if (v === '\n') console.log('nl')
        if (assumeCompleted) return tokens.push(Token(TokenType.SENTENCE, v))
        return tokens.push(Token(TokenType.UNCOMPLETED, v))
    })
    if (tokens.length > 0 && tokens[0].type === TokenType.COLON) tokens[0].type = TokenType.HEADING
    if (tokens.length > 0 && tokens[0].type === TokenType.UNCOMPLETED) tokens[0].type = TokenType.UNCOMPLETED_BOL
    if (tokens.length === 1 && tokens[0].type === TokenType.HEADING) tokens[0].type = TokenType.INDEPENDENT_HEADING
    if (tokens.length === 2 && tokens[0].type === TokenType.HEADING && tokens[1].type === TokenType.EMPTY_SENTENCE) tokens[0].type = TokenType.INDEPENDENT_HEADING
    return tokens
}
