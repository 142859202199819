const Select = {}
export default Select

const safetyNet = 10000

function getSelectionDirection(selection) {
    let position = selection.anchorNode.compareDocumentPosition(selection.focusNode)
    if (position & Node.DOCUMENT_POSITION_FOLLOWING) return 'ltr'
    if (position & Node.DOCUMENT_POSITION_PRECEDING) return 'rtl'
    if (selection.anchorNode === selection.focusNode && selection.focusOffset < selection.anchorOffset) {
        return 'ltr'
    }
    if (selection.anchorNode === selection.focusNode && selection.focusOffset > selection.anchorOffset) {
        return 'rtl'
    }
    return 'one'
}

function getAnchorPosition(container, selection) {
    let safety = 0
    let currentNode = selection.anchorNode
    if (!container.contains(currentNode)) return [-1, -1]
    if (currentNode === container && selection.anchorOffset > 0) currentNode = container.childNodes[selection.anchorOffset - 1]
    let baseNode = currentNode
    let lastNode = currentNode
    while (lastNode !== null && lastNode.nextSibling != null) {
        lastNode = lastNode.nextSibling
    }
    let traversedLastChild = [lastNode]
    let text = ''
    let breakNode = null
    let traversedNodes = []
    let lineNumber = 0
    let selectionDirection = getSelectionDirection(selection)


    // Iterate through others
    while (currentNode != null) {
        // Safety
        safety += 1
        if (safety > safetyNet) break

        // Collect nodes
        traversedNodes.push(currentNode)

        // Break
        if (currentNode.tagName === 'DIV') breakNode = currentNode
        if (breakNode != null) break

        //Characterize
        let previousNode = currentNode.previousSibling
        let parentNode = currentNode.parentNode
        let childNodes = currentNode.childNodes

        if (currentNode.nodeType === Node.TEXT_NODE && currentNode !== baseNode) text = currentNode.textContent + text
        if (currentNode === baseNode) {
            if (selectionDirection === 'ltr') text = currentNode.textContent.substring(0, selection.getRangeAt(0).startOffset) + text
            if (selectionDirection === 'rtl') text = currentNode.textContent.substring(0, selection.getRangeAt(0).endOffset) + text
            if (selectionDirection === 'one') text = currentNode.textContent.substring(0, selection.getRangeAt(0).endOffset) + text
        }

        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.tagName === 'BR') {
            lineNumber += 1
        }

        if (childNodes.length !== 0) {
            if (traversedLastChild[traversedLastChild.length - 1] === childNodes[childNodes.length - 1]) {
                traversedLastChild.pop()
                let lastNode = currentNode
                while (lastNode.nextSibling != null) {
                    lastNode = lastNode.nextSibling
                }
                traversedLastChild.push(lastNode)
            } else {
                currentNode = childNodes[childNodes.length - 1]
                traversedLastChild.push(currentNode)
                continue
            }
        }

        if (previousNode !== null) {
            currentNode = previousNode
            continue
        }

        if (parentNode !== null) {
            currentNode = parentNode
            continue
        }

    }

    //if (baseNode === container) return selection.anchorOffset * -1

    return [text.length, lineNumber]
}

function getFocusPosition(container, selection) {
    let safety = 0
    let currentNode = selection.focusNode
    if (!container.contains(currentNode)) return [-1, -1]
    if (currentNode === container && selection.focusOffset > 0) currentNode = container.childNodes[selection.focusOffset - 1]
    let baseNode = currentNode
    let lastNode = currentNode
    while (lastNode !== null && lastNode.nextSibling != null) {
        lastNode = lastNode.nextSibling
    }
    let traversedLastChild = [lastNode]
    let text = ''
    let breakNode = null
    let lineNumber = 0
    let selectionDirection = getSelectionDirection(selection)

    // Iterate through others
    while (currentNode != null) {
        // Safety
        safety += 1
        if (safety > safetyNet) break

        // Break
        if (currentNode.tagName === 'DIV') breakNode = currentNode
        if (breakNode != null) break

        //Characterize
        let previousNode = currentNode.previousSibling
        let parentNode = currentNode.parentNode
        let childNodes = currentNode.childNodes

        if (currentNode.nodeType === Node.TEXT_NODE && currentNode !== baseNode) text = currentNode.textContent + text
        if (currentNode === baseNode) {
            if (selectionDirection === 'ltr') text = currentNode.textContent.substring(0, selection.getRangeAt(0).endOffset) + text
            if (selectionDirection === 'rtl') text = currentNode.textContent.substring(0, selection.getRangeAt(0).startOffset) + text
            if (selectionDirection === 'one') text = currentNode.textContent.substring(0, selection.getRangeAt(0).startOffset) + text
            // console.log('dir:', selectionDirection)
            // console.log('cur', currentNode.textContent)
        }

        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.tagName === 'BR') {
            lineNumber += 1
        }


        if (childNodes.length !== 0) {
            if (traversedLastChild[traversedLastChild.length - 1] === childNodes[childNodes.length - 1]) {
                traversedLastChild.pop()
                let lastNode = currentNode
                while (lastNode.nextSibling != null) {
                    lastNode = lastNode.nextSibling
                }
                traversedLastChild.push(lastNode)
            } else {
                currentNode = childNodes[childNodes.length - 1]
                traversedLastChild.push(currentNode)
                continue
            }
        }

        if (previousNode !== null) {
            currentNode = previousNode
            continue
        }

        if (parentNode !== null) {
            currentNode = parentNode
            continue
        }
    }

    //if (baseNode === container) return selection.focusOffset * -1
    return [text.length, lineNumber]
}

function getUptoElement(container, element) {
    let safety = 0
    let currentNode = element
    if (!container.contains(currentNode)) return [-1, -1]
    let baseNode = currentNode
    let lastNode = currentNode
    while (lastNode !== null && lastNode.nextSibling != null) {
        lastNode = lastNode.nextSibling
    }
    let traversedLastChild = [lastNode]
    let text = ''
    let breakNode = null
    let traversedNodes = []
    let lineNumber = 0

    // Iterate through others
    while (currentNode != null) {
        // Safety
        safety += 1
        if (safety > safetyNet) break

        // Collect nodes
        traversedNodes.push(currentNode)

        // Break
        if (currentNode.tagName === 'DIV') breakNode = currentNode
        if (breakNode != null) break

        //Characterize
        let previousNode = currentNode.previousSibling
        let parentNode = currentNode.parentNode
        let childNodes = currentNode.childNodes

        if (currentNode.nodeType === Node.TEXT_NODE && currentNode !== baseNode) text = currentNode.textContent + text
        if (currentNode === baseNode) {
            text = currentNode.textContent + text
        }

        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.tagName === 'BR') {
            lineNumber += 1
        }

        if (childNodes.length !== 0) {
            if (traversedLastChild[traversedLastChild.length - 1] === childNodes[childNodes.length - 1]) {
                traversedLastChild.pop()
                let lastNode = currentNode
                while (lastNode.nextSibling != null) {
                    lastNode = lastNode.nextSibling
                }
                traversedLastChild.push(lastNode)
            } else {
                currentNode = childNodes[childNodes.length - 1]
                traversedLastChild.push(currentNode)
                continue
            }
        }

        if (previousNode !== null) {
            currentNode = previousNode
            continue
        }

        if (parentNode !== null) {
            currentNode = parentNode
            continue
        }

    }

    //if (baseNode === container) return selection.anchorOffset * -1

    return [text.length, lineNumber]
}

Select.getUptoElement = getUptoElement

Select.getSelection = (container, selection) => {
    let anchorPosition = getAnchorPosition(container, selection)
    let focusPosition = getFocusPosition(container, selection)
    return [anchorPosition[0], focusPosition[0], anchorPosition[1], focusPosition[1]]
}

Select.setSelection = (container, select) => {
    let anchorDistance = select[0]
    let focusDistance = select[1]
    let anchorLine = select[2]
    let focusLine = select[3]
    let selection = window.getSelection()
    let safety = 0
    let currentNode = container.childNodes[0]

    let firstNode = currentNode
    let traversedNodes = []
    let anchorNode = null
    let anchorOffset = null
    let focusNode = null
    let focusOffset = null
    let lineNumber = 0

    while (firstNode != null && firstNode.previousSibling != null) {
        firstNode = firstNode.previousSibling
    }
    let traversedLastChild = [firstNode]
    let text = ''
    let breakNode = null

    if (anchorDistance < 0) {
        anchorNode = container
        anchorOffset = anchorDistance * -1
    }

    if (focusDistance < 0) {
        focusNode = container
        focusOffset = focusDistance * -1
    }

    // Iterate through others
    while (currentNode != null) {
        // Safety
        safety += 1
        if (safety > safetyNet) break

        // Break
        if (currentNode.tagName === 'DIV') breakNode = currentNode
        if (breakNode != null) break

        // Collect nodes
        traversedNodes.push(currentNode)

        let nextNode = currentNode.nextSibling
        let parentNode = currentNode.parentNode
        let childNodes = currentNode.childNodes

        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.tagName === 'BR') {
            lineNumber++
        }

        if (currentNode.nodeType === Node.TEXT_NODE) {
            text += currentNode.textContent
            if (anchorNode == null &&
                anchorDistance >= (text.length - currentNode.textContent.length) &&
                text.length >= anchorDistance && lineNumber === anchorLine) {
                anchorNode = currentNode
                anchorOffset = anchorDistance - text.length + currentNode.textContent.length
            }

            if (focusNode == null &&
                focusDistance >= (text.length - currentNode.textContent.length) &&
                text.length >= focusDistance && lineNumber === focusLine) {
                focusNode = currentNode
                focusOffset = focusDistance - text.length + currentNode.textContent.length
            }
        }

        if (focusNode != null && anchorNode != null) break

        if (childNodes.length !== 0) {
            if (traversedLastChild[traversedLastChild.length - 1] === childNodes[0]) {
                traversedLastChild.pop()
                let firstNode = currentNode
                while (firstNode.previousSibling != null) {
                    firstNode = firstNode.previousSibling
                }
                traversedLastChild.push(firstNode)
            } else {
                currentNode = childNodes[0]
                traversedLastChild.push(currentNode)
                continue
            }
        }

        if (nextNode !== null) {
            currentNode = nextNode
            continue
        }

        if (parentNode !== null) {
            currentNode = parentNode
            continue
        }

    }

    // if (currentNode === container) {
    //     console.log(currentNode)
    //     let eyesOnNode = traversedNodes.pop()
    //     while (typeof eyesOnNode !== 'undefined') {
    //         if (eyesOnNode.nodeType !== Node.TEXT_NODE) {
    //             eyesOnNode = traversedNodes.pop()
    //             continue
    //         }
    //         if (anchorNode === null) anchorOffset = eyesOnNode.textContent.length
    //         if (anchorNode === null) anchorNode = eyesOnNode
    //         if (focusNode === null) focusOffset = eyesOnNode.textContent.length
    //         if (focusNode === null) focusNode = eyesOnNode
    //         eyesOnNode = traversedNodes.pop()
    //     }
    // }

    if (currentNode === container && container.childNodes.length !== 0) {
        let walkNode = container.childNodes[0]
        let lineCounter = 0
        let nodeCounter = 1
        anchorNode = container
        focusNode = container
        anchorOffset = null
        focusOffset = null
        while (walkNode !== null) {
            if (walkNode.tagName === 'BR') {
                lineCounter += 1
            }
            // if (lineCounter === anchorLine) anchorOffset = nodeCounter + 1
            // if (lineCounter === focusLine) focusOffset = nodeCounter + 1
            // Before we had a +1
            if (lineCounter === anchorLine) anchorOffset = nodeCounter
            if (lineCounter === focusLine) focusOffset = nodeCounter
            if (anchorOffset !== null && focusOffset !== null) break
            walkNode = walkNode.nextSibling
            nodeCounter++
        }
        if ( anchorOffset === null ) anchorOffset = 0
        if ( focusOffset === null ) focusOffset = 0
        console.log('here')
    }

    // console.log('container:', container)
    // console.log('current node:', currentNode)
    // console.log('anchor:', anchorNode)
    // console.log('focus:', focusNode)
    // // console.log('anchor parent:', anchorNode.parentNode)
    // // console.log('focus parent:', focusNode.parentNode)
    // console.log('AA:', anchorDistance, ',', anchorOffset)
    // console.log('FF:', focusDistance, ',', focusOffset)

    if (anchorNode === null) anchorNode = container
    if (focusNode === null) focusNode = container
    if (focusOffset === null) focusOffset = 0
    if (anchorOffset === null) anchorOffset = 0

    selection.removeAllRanges()
    const range = document.createRange()
    range.setStart(anchorNode, anchorOffset)
    range.setEnd(focusNode, focusOffset)
    selection.addRange(range)

    // Scroll into view ??Do I need this
    // if (currentNode === container && container.childNodes.length !== 0) container.childNodes[anchorOffset - 1].scrollIntoView({
    //     block: 'end',
    // })
    // if (currentNode === container && container.childNodes.length === 0) container.scrollIntoView({
    //     block: 'end',
    // })

    if (anchorNode !== null && anchorNode.nodeType === Node.TEXT_NODE && anchorNode.parentNode !== container) anchorNode.parentNode.scrollIntoView({
        block: 'end',
    })
}



Select.lineTextBeforeSelection = (container, selection) => {
    let safety = 0
    let currentNode = selection.focusNode
    if (!container.contains(currentNode)) return [null, [], null]
    //if (currentNode.tagName === 'DIV') return ['', [], null]
    let selectionDirection = getSelectionDirection(selection)
    if (currentNode.tagName !== 'DIV' && selectionDirection === 'ltr') currentNode = selection.anchorNode
    if (currentNode.tagName !== 'DIV' && selectionDirection === 'rtl') currentNode = selection.focusNode
    if (currentNode.tagName === 'DIV' && selectionDirection === 'one') {
        let nodeOrder = selection.focusOffset === 0? 0 : selection.focusOffset - 1
        currentNode = container.childNodes[nodeOrder]
    }
    // console.log('cur', currentNode)
    if (currentNode === null ) return ['', [], null]
    if (container.childNodes.length === 0 ) return ['', [], null]
    

    let baseNode = currentNode
    let lastNode = currentNode
    while (lastNode !== null && lastNode.nextSibling != null) {
        lastNode = lastNode.nextSibling
    }
    let traversedLastChild = [lastNode]
    let text = ''
    let breakNode = null
    let traversedNodes = []

    // Iterate through others
    while (currentNode != null) {
        // Safety
        safety += 1
        if (safety > safetyNet) break

        // Break
        if (currentNode === container) breakNode = currentNode
        if (currentNode.tagName === 'BR') breakNode = currentNode
        if (breakNode != null) break

        // Collect nodes
        traversedNodes.push(currentNode)

        //Characterize
        let previousNode = currentNode.previousSibling
        let parentNode = currentNode.parentNode
        let childNodes = currentNode.childNodes

        if (currentNode.nodeType === Node.TEXT_NODE && currentNode !== baseNode) {
            //console.log(currentNode, currentNode.textContent)
            text = currentNode.textContent + text
        }

        if (currentNode.nodeType === Node.TEXT_NODE && currentNode === baseNode) {
            //console.log(currentNode, currentNode.textContent.substring(0, selection.getRangeAt(0).startOffset))
            text = currentNode.textContent.substring(0, selection.getRangeAt(0).startOffset) + text
        }

        if (childNodes.length !== 0) {
            if (traversedLastChild[traversedLastChild.length - 1] === childNodes[childNodes.length - 1]) {
                traversedLastChild.pop()
                let lastNode = currentNode
                while (lastNode.nextSibling != null) {
                    lastNode = lastNode.nextSibling
                }
                traversedLastChild.push(lastNode)
            } else {
                currentNode = childNodes[childNodes.length - 1]
                traversedLastChild.push(currentNode)
                continue
            }
        }

        if (previousNode !== null) {
            currentNode = previousNode
            continue
        }

        if (parentNode !== null) {
            currentNode = parentNode
            continue
        }

    }

    return [text, traversedNodes, breakNode]
}

Select.lineTextAfterSelection = (container, selection) => {
    let safety = 0
    let currentNode = selection.focusNode
    if (!container.contains(currentNode)) return [null, [], null]
    if (currentNode.tagName === 'DIV') return ['', [], null]
    if (getSelectionDirection(selection) === 'ltr') currentNode = selection.focusNode
    if (getSelectionDirection(selection) === 'rtl') currentNode = selection.anchorNode

    let tempRange = selection.getRangeAt(0)
    if (tempRange.endOffset === 1 &&
        tempRange.startOffset === 1 &&
        tempRange.startContainer === tempRange.endContainer &&
        tempRange.startContainer.nodeType !== Node.TEXT_NODE
    ) {
        currentNode = currentNode.nextSibling
    }
    let baseNode = currentNode
    let firstNode = currentNode
    let traversedNodes = []

    while (firstNode != null && firstNode.previousSibling != null) {
        firstNode = firstNode.previousSibling
    }
    let traversedLastChild = [firstNode]
    let text = ''
    let breakNode = null

    if (firstNode === null) return [text, traversedNodes, breakNode]

    // Iterate through others
    while (currentNode != null) {
        // Safety
        safety += 1
        if (safety > safetyNet) break

        // Break
        if (currentNode === container) breakNode = currentNode
        if (currentNode.tagName === 'BR') breakNode = currentNode
        if (breakNode != null) break

        // Collect nodes
        traversedNodes.push(currentNode)

        let nextNode = currentNode.nextSibling
        let parentNode = currentNode.parentNode
        let childNodes = currentNode.childNodes

        if (currentNode.nodeType === Node.TEXT_NODE && currentNode !== baseNode) {
            text += currentNode.textContent
        }

        if (currentNode.nodeType === Node.TEXT_NODE && currentNode === baseNode) {
            text += currentNode.textContent.substring(selection.getRangeAt(0).endOffset)
        }

        if (childNodes.length !== 0) {
            if (traversedLastChild[traversedLastChild.length - 1] === childNodes[0]) {
                traversedLastChild.pop()
                let firstNode = currentNode
                while (firstNode.previousSibling != null) {
                    firstNode = firstNode.previousSibling
                }
                traversedLastChild.push(firstNode)
            } else {
                currentNode = childNodes[0]
                traversedLastChild.push(currentNode)
                continue
            }
        }

        if (nextNode !== null) {
            currentNode = nextNode
            continue
        }

        if (parentNode !== null) {
            currentNode = parentNode
            continue
        }

    }
    return [text, traversedNodes, breakNode]
}

Select.getLineAt = (container, lineNumber) => {
    let childNodesArray = Array.from(container.childNodes)
    let breakCount = 0
    let beginNode = 0
    for (let i = 0; i < childNodesArray.length; i++) {
        if (lineNumber === 0) break
        if (breakCount === lineNumber) { beginNode = i; break; }
        if (childNodesArray[i].tagName === 'BR') breakCount += 1
    }
    let lastNode = childNodesArray.length - 1
    for (let i = beginNode; i < childNodesArray.length; i++) {
        if (childNodesArray[i].tagName === 'BR') { lastNode = i; break; }
    }
    childNodesArray.slice(beginNode, lastNode)
    return childNodesArray.slice(beginNode, lastNode)
}

Select.getLineBreak = (container, lineNumber) => {
    if (container.childNodes.length === 0) return null
    if (lineNumber === 0) return null
    let walkNode = container.childNodes[0]
    let lineCounter = 0
    let nodeCounter = 1
    let lineBreak = null
    while (walkNode !== null) {
        if (walkNode.tagName === 'BR') {
            lineCounter += 1
        }
        if (lineCounter === lineNumber) lineBreak = walkNode
        if (lineBreak !== null) break
        walkNode = walkNode.nextSibling
        nodeCounter++
    }
    return lineBreak
}