
import { useEffect, useState } from "react"
import Logo from "../Logo/Logo"
import { BiDotsVerticalRounded, BiPlusCircle } from 'react-icons/bi'

function timeDifference(date) {
    let currentTime = new Date();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let timeDiffInMs = currentTime.getTime() - date.getTime();
    let timeDiffInS = timeDiffInMs / 1000;
    let timeDiffInM = timeDiffInS / 60;
    let timeDiffInH = timeDiffInM / 60;
    let timeDiffInD = timeDiffInH / 24;
    if (timeDiffInS < 60) return `${Math.round(timeDiffInS)} seconds ago`;
    if (timeDiffInM < 60) return `${Math.round(timeDiffInM)} minutes ago`;
    if (timeDiffInH < 24) return `${Math.round(timeDiffInH)} hours ago`;
    if (Math.round(timeDiffInD) == 1) return 'Yesterday';
    if (Math.round(timeDiffInD) < 7) return `${Math.round(timeDiffInD)} days ago`;
    return `${month[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

}

const documentsExample = [
    {
        name: 'Example file',
        lastUpdated: '1 day ago',
    },
    {
        name: 'Another example file',
        lastUpdated: '1 day ago',
    },
    {
        name: 'Another example file that could span a very very long space without realiazing',
        lastUpdated: '1 day ago',
    },
]

function DocumentsToolbar(props) {
    function onClick(e) {
        window.location.href = '/editor'
    }

    return (<div className='documents-toolbar'>
        <button className='create-document-button' onClick={onClick}><span className="icon"><BiPlusCircle /></span>New document</button>
        </div>)
}

function Documents(props) {
    const listOfDocuments = props.list
    const documentOnClick = props.documentOnClick
    const documentMenuOnClick = props.documentMenuOnClick

    const list = listOfDocuments.map((document, k) => {
        return (<div className='document' key={k} data-index={k} onClick={documentOnClick}>
            <div className="name">{document.name}</div>
            <div className="last-updated">{document.lastUpdated}</div>
            <div className="controls" onClick={documentMenuOnClick}><BiDotsVerticalRounded /></div>
        </div>)
    })

    return (<div className='documents-list'>
    <DocumentsToolbar />
    {list}
    </div>)
}

function Avatar() {
    return  <button className='avatar'><div className="avatar-letters">HC</div></button>
}

function User() {
    return <div className='user-bar'>
       <button className='avatar'><div className="avatar-letters">HC</div></button>
    </div>
}

function DocumentMenu(props) {
    const style = props.style
    const className = props.className
    return (
        <div className={className} style={style}>
            <div className='option'>Remove</div>
            <div className='option'>Open in new tab</div>
        </div>
    )
}

function Explorer() {
    const [documents, setDocuments] = useState(documentsExample)
    const [documentMenuTop, setDocumentMenuTop] = useState(0)
    const [documentMenuLeft, setDocumentMenuLeft] = useState(0)
    const [documentMenuRight, setDocumentMenuRight] = useState(0)
    const [documentMenuDisplay, setDocumentMenuDisplay] = useState(false)
    useEffect(() => { }, [])

    function documentOnClick(e) {
        let id = null
        id = e.target.getAttribute('data-index')
        if (id === null) id = e.target.parentElement.getAttribute('data-index')
        if (id !== null) id = parseInt(id)
        if (id === null) return
        console.log(documents[id])
    }

    function closeDocumentMenu() {
        setDocumentMenuDisplay(false)
    }

    function documentMenuOnClick(e) {
        let doc = e.target.parentElement
        let position = doc.getBoundingClientRect();
        setDocumentMenuDisplay(true)
        setDocumentMenuTop(position.bottom)
        setDocumentMenuRight(window.innerWidth - position.right)
        setTimeout(() => document.addEventListener("click", closeDocumentMenu, { once: true}), 300)
    }

    return (
        <div className='main-container'>
            <div className='header'>
                <Logo />
                <div className="logo-text">ypefs</div>
                <input className='top-search-bar' placeholder="Search" />
                <User />
            </div>
            <div className='documents-list-container'>
                <Documents list={documentsExample}
                    documentOnClick={documentOnClick}
                    documentMenuOnClick={documentMenuOnClick}
                />
            </div>
            <DocumentMenu className={`document-menu ${documentMenuDisplay !== true && 'hide'}`} style={{ right: documentMenuRight + 'px', top: documentMenuTop + 'px' }} />
        </div>
    )
}

export default Explorer